body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Axiforma";
    src: url("assets/fonts/Axiforma-Regular.woff") format("woff");
}

* {
    box-sizing: border-box;
}

a,
a:active,
a:hover,
a:visited {
    text-decoration: none;
}

a {
    color: #e30813;
}

#zdrmWPhI {
    left: unset !important;
    top: unset !important;
    bottom: 5px !important;
    right: 10px !important;
}

#zdrmWPhI.zdrm-fixed {
    z-index: 25 !important;
}

.zdrm-webphone-box.zdrm-phone{
    box-shadow: 0 0 5px 1px black;
}
